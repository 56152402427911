<template>
    <div class="flex w-full sm:flex-col lg:flex-row my-5 text-lg">

        <div
            class="sm:w-full  lg:w-1/2 xl:w-full mr-5 sm:mb-5 lg:mb-0  dark:bg-gray-800 bg-white shadow-md dark:shadow-none p-5 rounded">

            <h3 class="mb-5 text-xl">Выберите информацию для выгрузки</h3>

            <Search v-if="admin" :filter-by="['uf_name','uf_contract']" :items="clients"
                    v-model="selectedCompanies"></Search>

            <div v-if="admin">
                <label v-for="i in list_admin" :key="'admin-'+i.value" class="select-none mb-3 block">
                    <input v-model="types"
                           :value="i.value"
                           type="checkbox"
                           class="mx-2 transform scale-125">{{ i.name }}
                </label>
            </div>

            <div v-else>
                <label v-for="i in list" :key="i.value" class="select-none mb-3 block">
                    <input v-model="types"
                           :value="i.value"
                           type="checkbox"
                           class="mx-2 transform scale-125">{{ i.name }}
                </label>
            </div>
            <DatePicker v-model="period" :range="true" :format="'DD.MM.YYYY'" ref="period" class="
                    text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base dark:placeholder-gray-400
                         transition duration-500 ease-in-out transform border-transparent
                          bg-white dark:border-transparent border border-gray-200 rounded focus:border-blueGray-500 focus:bg-white dark:bg-gray-700
                          dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
                          dark:focus:text-white mb-10"
                        lang="ru"
                        placeholder="Период"
                        :value-type="'format'"
                        :input-class="'dark:bg-gray-700  outline-none bg-white dark:text-white text-black  w-full '"
                        :popup-class="'dark:bg-gray-700'"></DatePicker>

            <ProgressLinear v-show="loading"></ProgressLinear>

            <div class="border-t py-5 mt-10">
                <button class="text-6xl dark:text-gray-200 text-gray-700 mr-5" @click="exportXLSX">
                    <i class="fas fa-file-excel"></i>
                </button>

                <button class="text-6xl text-red-600 transition duration-500"
                        :class="{'transform rotate-12 ':pageSelect}" @click="pageSelect = !pageSelect">
                    <i class="fas fa-file-pdf"></i>
                </button>

                <transition name="slide-fade">
                    <div class="inline-flex mx-2 bg-gray-900 px-3 " v-show="pageSelect">
                        <button class="mx-3 transform hover:scale-150 transition duration-200"
                                @click="exportPDF('A4')">A4
                        </button>
                        <button class="mx-3 transform hover:scale-150 transition duration-200"
                                @click="exportPDF('A3')">A3
                        </button>
                        <button class="mx-3 transform hover:scale-150 transition duration-200"
                                @click="exportPDF('A2')">A2
                        </button>
                        <button class="mx-3 transform hover:scale-150 transition duration-200"
                                @click="exportPDF('A1')">A1
                        </button>
                    </div>
                </transition>

            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {writeXLSX, writePDF} from "@/mixins/reports";

import ProgressLinear from './ProgressLinear'
import Search from './Search'

import {mapGetters} from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name: "Export",
    components: {DatePicker, ProgressLinear, Search},
    data: () => ({
        loading: false,
        selectedCompanies: [],
        types: [],
        period: null,
        message: false,
        pageSelect: false,
        list: [
            {name: 'Мой транспорт', value: 'vehicles'},
            {name: 'Мои перевозки', value: 'transportations'},
            {name: 'Мои платежи', value: 'payments'},
            {name: 'Регистрации', value: 'registrations'}

        ],
        list_admin: [
            {name: 'Транспорт', value: 'vehicles'},
            {name: 'Перевозки', value: 'transportations'},
            {name: 'Платежи', value: 'payments'},
            {name: 'Регистрации', value: 'registrations'},
        ],
        content: {}
    }),
    props: {
        admin: {
            type: Boolean,
        },
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            clients: 'admin/contracts'
        }),

    },
    created() {
        if (this.admin) {
            this.$store.dispatch('admin/getContractsAll')
        }
    },
    methods: {
        fetch() {

            let link = 'https://declarant.by/rest/user/client/export'
            let payload = {types: this.types, period: this.period}
            if (this.admin) {
                if (!this.selectedCompanies || !this.selectedCompanies.length) {
                    this.$notify({
                        title: 'Внимание',
                        text: 'Не выбрана организация',
                        type: 'warn'
                    })
                    return
                }
                link = 'https://declarant.by/rest/user/client/admin-export'
                payload = {
                    types: this.types,
                    companies: this.selectedCompanies,
                    period: this.period
                }
            }

            this.loading = true
            return axios.post(link, payload)
                .then(res => {
                    this.loading = false
                    this.content = res.data.data
                    for (let i in this.content) {
                        if (this.content[i].items === null) {
                            delete this.content[i]
                        }
                    }
                })
                .catch(err => {
                    this.loading = false
                    this.$notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })


        },
        async exportXLSX() {
            if (!this.types.length) {
                this.$notify({
                    title: 'Внимание',
                    text: 'Выберите необходимые категогии из списка',
                    type: 'warn'
                })
                return false
            }

            await this.fetch()

            let params = {admin: this.admin}

            if (!this.admin) {
                params = {...params, uf_name: this.user.uf_name}
            }

            writeXLSX(this.content, false, params)

        },
        async exportPDF(pageSize) {
            if (!this.types.length) {
                this.$notify({
                    title: 'Внимание',
                    text: 'Выберите необходимые категогии из списка',
                    type: 'warn'
                })
                return false
            }
            await this.fetch()

            writePDF(this.content, pageSize)

        }
    }
}
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

</style>