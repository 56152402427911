import Vue from "vue";
import XLSX from "xlsx-js-style";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

function containBlocks(content) {
    return !Object.keys(content).includes('items') || !Object.keys(content).includes('headings');

}

function styleCells(ws, cols,) {
    // Шапка документа
    ws['!merges'] = [{s: {r: 0, c: 0}, e: {r: 0, c: cols - 1}}]
    ws['!rows'] = [{hpx: 45}]
    ws['A1'].s = {
        font: {bold: true, sz: 16},
        alignment: {wrapText: true, horizontal: 'center', vertical: 'center'}
    }
    for (let i in ws) {
        if (typeof (ws[i]) != "object") continue;
        let cell = XLSX.utils.decode_cell(i);
        if (cell.r === 1) {
            ws[i].s = {
                font: {bold: true},
                alignment: {
                    wrapText: true,
                    horizontal: 'center',
                    vertical: 'center'
                }
            };
        }
    }
    return ws
}

function writeXLSX(content = {}, title = {}, {admin = false, uf_name = ""} = {}) {

    const dateString = new Date().toISOString().slice(0, 10).replace(/-/g, "_")
    const name = `${dateString}_отчет_.xlsx`
    const workbook = XLSX.utils.book_new();

    // eslint-disable-next-line no-useless-catch
    try {
        if (containBlocks(content)) {
            for (let block in content) {
                if (content[block].items.length) {

                    const [firstItem] = content[block].items
                    const [key] = Object.keys(firstItem)

                    let h = {...content[block].headings}
                    let ws_page_name = content[block].blockname
                    if (ws_page_name.length > 30) ws_page_name = ws_page_name.substr(0, 27);

                    content[block].items.unshift(h)

                    let title = {}

                    if (admin) {
                        title[key] = content[block].blockname
                    } else {
                        let company = uf_name || ""
                        title[key] = `${content[block].blockname}  ${company}`.trim()
                    }

                    let data = [title, ...content[block].items]
                    let ws = XLSX.utils.json_to_sheet(data, {skipHeader: true})
                    let cols = Object.keys(content[block].items[0]).length
                    ws = styleCells(ws, cols)
                    XLSX.utils.book_append_sheet(workbook, ws, ws_page_name)
                }
            }
        } else {
            const h = {...content.headings}
            let ws_page_name = content.blockname
            if (ws_page_name.length > 30) {
                ws_page_name = ws_page_name.substr(0, 27)
            }
            content.items.unshift(h)
            let data = [title, ...content.items]
            let ws = XLSX.utils.json_to_sheet(data, {skipHeader: true})
            let cols = Object.keys(content.items[0]).length
            ws = styleCells(ws, cols)
            XLSX.utils.book_append_sheet(workbook, ws, ws_page_name)
        }

        XLSX.writeFile(workbook, name)

    } catch (e) {
        if (e.message === 'Workbook is empty') {
            Vue.notify({
                title: 'Внимание',
                text: 'Выборка пуста. Попробуйте изменить параметры',
                type: 'error'
            })
        }
    }
}

function writePDF(content, pageSize) {
    let headings_array = [];
    let items_array = [];

    for (let blocks in content) {
        headings_array = [...headings_array, content[blocks]['headings']];
        items_array = [...items_array, content[blocks]['items']]
    }

    items_array = items_array.map((el, index) => {
        el.unshift(headings_array[index])
        return el
    })

    let parsedItems = items_array.map(object => {
        if (object) {
            let strArr = [];
            object.forEach(el => {
                let acc = [];
                for (const key in el) {
                    if (Object.hasOwnProperty.call(el, key)) {
                        const element = el[key];
                        acc = [...acc, element]
                    }
                }
                strArr = [...strArr, acc]
            })

            return strArr
        }
        return [];
    });

    parsedItems = parsedItems.filter(i => i.length > 0)


    let result = parsedItems.map((el, index) => {
        return {
            pageBreak: parsedItems.length - 1 === index ? 'none' : 'after',
            headerRows: 1,
            layout: 'lightHorizontalLines',
            table: {
                headerRows: 1,
                body: el
            }
        }
    })

    var docDefinition = {
        pageOrientation: 'landscape',
        pageSize: pageSize,
        pageMargins: [40, 60, 40, 60],
        content: result
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(docDefinition).open();


}

export {writeXLSX, writePDF}