<template>
    <div class="ui-row">
        <div class="ui-col bg-transparent">
            <div class="loader linear indefinite">
                <div class="inside"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProgressLinear"
}
</script>

<style scoped>
.ui-row {
    display: flex;
    flex: 1;
}
.ui-row .ui-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}
.loader {
    position: relative;
    margin: 0 auto;
    width: 100px;
}
.loader:before {
    content: "";
    display: block;
    padding-top: 100%;
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -21px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
@keyframes growandshrink {
    0% {
        left: 0%;
        right: 100%;
        animation-timing-function: ease-in;
    }
    33% {
        left: 10%;
        right: 25%;
    }
    66% {
        left: 75%;
        right: 10%;
    }
    100% {
        left: 100%;
        right: 0%;
        animation-timing-function: ease-out;
    }
}
.loader.linear.indefinite {
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, 0.1);
    position: relative;
}
.loader.linear.indefinite .inside {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: #0097e6;
    animation: growandshrink 1s linear infinite;
}
.dark .loader.linear.indefinite {
    background: rgba(255, 255, 255, 0.2);
}
.dark .loader.linear.indefinite .inside {
    background: white;
}


</style>