<template>
    <div class="search w-full relative my-4" @focusin="isFocused = true">
        <input type="text" placeholder="Выберите организацию"
               class="search__input h-full w-full
               rounded
               py-2 px-2
               bg-transparent border dark:border-gray-700 border-gray-200 appearance-none outline-none
               dark:text-gray-200 text-gray-700 relative "
               v-model="search">
        <div v-show="isFocused"
             class="search__items-list absolute top-10 left-0 w-full max-h-52 dark:bg-gray-900 bg-white overflow-y-scroll z-20 p-2 ">

            <div class="search__single-item cursor-pointer dark:hover:text-green-400 px-3" @click="selectItem(item)"
                 v-for="(item,idx) in itemsFiltered"
                 :class="{'bg-green-500': selected.id === item.id}"
                 :key="'search-item-'+idx">{{
                    item.uf_name
                }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Search",
    data: () => ({
        search: '',
        itemsFiltered: [],
        isFocused: false,
        selected: {}
    }),
    props: {
        items: {
            type: Array,
            required: true
        },
        filterBy: {
            type: Array,
            required: true
        }
    },
    created() {
        document.addEventListener('click', this.checkFocusTarget)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.checkFocusTarget)
    },
    watch: {
        search: {
            handler(newVal) {
                if (newVal === '') {
                    this.selected = {}
                    this.itemsFiltered = this.items
                } else {
                    this.itemsFiltered = this.filterItems()

                }
            },
        },
        items: {
            handler(newVal) {
                this.itemsFiltered = newVal
            },
            deep: true,
            immediate: true

        }
    },
    methods: {
        checkFocusTarget(event) {
            let elem = event.target
            if (elem.classList.contains('search__items-list')
                || elem.classList.contains('search__single-item')
                || elem.classList.contains('search__input')) {
                return
            }
            return this.isFocused = false
        },
        selectItem(item) {
            this.isFocused = false
            this.selected = item
            this.search = this.selected.uf_name
            let id = item.id
            this.$emit('input', [id])
        },
        filterItems() {
            return this.items.filter(i => {
                let equal = []
                this.filterBy.forEach((param) => {
                    if (i[param] !== null) {
                        if (i[param].toLowerCase().includes(this.search.trim().toLowerCase())) {
                            equal.push(true)
                        }
                    }
                })
                if (equal.includes(true)) {
                    return i
                }
            })
        }
    }

}
</script>

<style scoped>

</style>